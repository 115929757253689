import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import type { MetaFunction } from '@remix-run/cloudflare';
import { Links, Meta, Outlet, Scripts, ScrollRestoration, isRouteErrorResponse, useRouteError } from '@remix-run/react';
import Header from './components/layout/header';
import RadixProvider from './rdt/radixProvider';
import TransactionNotification from './components/transaction-notification';

import './styles/app.css';
import Footer from './components/layout/footer';
// import { appsignal } from './lib/appsignal';

export function links() {
   return [
      // { rel: 'stylesheet', href: styles },
      // { rel: 'shortcut icon', type: 'image/svg+xml', href: 'https://defiplaza.net/assets/images/logo/icon.svg' },
      // { rel: 'mask-icon', sizes: 'any', href: 'https://defiplaza.net/assets/images/logo/icon.svg', color: '#5D0FC0' },
   ];
}

export const meta: MetaFunction = () => {
   return [
      { charset: 'utf-8' },
      { title: 'Astrolescent - Access the Best Prices on the Radix Network' },
      { description: 'Astrolescent aggregates all liquidity from the top DEXes on Radix: OciSwap, DefiPlaza, DogeCubeX, RadixPlanet, CaviarSwap and more.' }
   ];
};


function App() {
   return (
     <Outlet />
   );
}


export default withSentry(App);

export function ErrorBoundary() {
    const error = useRouteError();

    if (error instanceof Error) {
       // appsignal.sendError(error, (span) => {
       //    span.setAction('CatchAll');
       // });
    }

    // when true, this is what used to go to `CatchBoundary`
    if (isRouteErrorResponse(error)) {
       return (
             <html className="bg-middle-middle bg-astrolescent min-h-full bg-cover bg-fixed" lang="en">
                 <head>
                     <Meta />
                     <meta charSet="utf-8" />
                     <meta name="viewport" content="width=device-width,initial-scale=1" />

                     <meta property="og:locale" content="en_US" />
                     <meta property="og:type" content="website" />
                     <meta property="og:site_name" content="Astrolescent" />
                     <meta property="og:image" content="https://astrolescent.com/assets/img/social-media-v3.png" />
                     <meta property="og:image:type" content="image/png" />
                     <meta name="twitter:card" content="summary_large_image" />
                     <meta name="twitter:site" content="@Astrolescent" />

                     <meta name="theme-color" content="#2A163A" />
                     <link rel="icon" type="image/png" href="/assets/img/icons/icon@32w.png" />
                     <link rel="icon" sizes="192x192" href="/assets/img/icons/icon@192w.png" />
                     <link rel="apple-touch-icon" href="/assets/img/icons/icon@152w.png" />
                     <meta name="msapplication-square310x310logo" content="/assets/img/icons/icon@310w.png" />
                     <Links />
                 </head>
                 <body className="min-h-full lining-nums text-black selection:bg-astro selection:text-white">
                     <RadixProvider>
                         <div className="mx-4 my-8 md:container md:mx-auto md:max-w-lg">
                             <Header />
                             <main className="my-4 rounded-lg bg-white p-4 shadow-lg md:my-8">
                                 <div>
                                     <h1>Uh oh ...</h1>
                                     <p>Something went wrong.</p>
                                     <p className="mt-4">
                                         Status: <span className="font-mono">{error.status}</span>
                                     </p>
                                     <p>{error.statusText}</p>
                                 </div>
                                 <TransactionNotification />
                                 <ScrollRestoration />
                             </main>
                             <Footer />
                         </div>
                     </RadixProvider>

                     <Scripts />
                 </body>
             </html>
         );
    }

    // Don't forget to typecheck with your own logic.
    // Any value can be thrown, not just errors!
    let errorMessage = 'Unknown error';
    captureRemixErrorBoundaryError(error);
    // if (isDefinitelyAnError(error)) {
    //    errorMessage = error.message;
    // }

    return (
         <html className="bg-middle-middle bg-astrolescent min-h-full bg-cover bg-fixed" lang="en">
             <head>
                 <Meta />
                 <meta charSet="utf-8" />
                 <meta name="viewport" content="width=device-width,initial-scale=1" />

                 <meta property="og:locale" content="en_US" />
                 <meta property="og:type" content="website" />
                 <meta property="og:site_name" content="Astrolescent" />
                 <meta property="og:image" content="https://astrolescent.com/assets/img/social-media-v3.png" />
                 <meta property="og:image:type" content="image/png" />
                 <meta name="twitter:card" content="summary_large_image" />
                 <meta name="twitter:site" content="@Astrolescent" />

                 <meta name="theme-color" content="#2A163A" />
                 <link rel="icon" type="image/png" href="/assets/img/icons/icon@32w.png" />
                 <link rel="icon" sizes="192x192" href="/assets/img/icons/icon@192w.png" />
                 <link rel="apple-touch-icon" href="/assets/img/icons/icon@152w.png" />
                 <meta name="msapplication-square310x310logo" content="/assets/img/icons/icon@310w.png" />
                 <Links />
             </head>
             <body className="min-h-full lining-nums text-black selection:bg-astro selection:text-white">
                 <RadixProvider>
                     <div className="mx-4 my-8 md:container md:mx-auto md:max-w-lg">
                         <Header />
                         <main className="my-4 rounded-lg bg-white p-4 shadow-lg md:my-8">
                             <h1>Uh oh ...</h1>
                             <p>Something went wrong.</p>
                             <p className="mt-4">{errorMessage}</p>
                             <TransactionNotification />
                             <ScrollRestoration />
                         </main>
                         <Footer />
                     </div>
                 </RadixProvider>

                 <Scripts />
             </body>
         </html>
     );
}